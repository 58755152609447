<template>
  <div v-if="reservation">
    <ui-section-header>
      <template v-slot:title>{{
        $t('Components.Reservation.ReservationNotes.Subtitle_Notes')
      }}</template>
    </ui-section-header>

    <div class="columns">
      <div class="column">
        <notes
          key="bookingNotes"
          :typeId="'Reservation'"
          :header="
            $t(
              'Components.Reservation.ReservationNotes.Header_NotesOfReservation'
            ) + reservation.Id
          "
          :itemId="reservation.Id"
          @noteCreated="noteCreated"
          @noteDeleted="noteDeleted"
          @noteEdited="noteEdited"
          :workEnvironment="'Manage'"
          :environmentId="reservation.LocationId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
const Notes = () => import('@/components/Notes/Notes')

export default {
  name: 'reservation-notes',

  components: {
    notes: Notes,
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  methods: {
    noteCreated(newNote) {
      this.reservation.Notes.push(newNote)
      // this.$store.dispatch('fillVersionStoreDataSets', this.reservation)
    },

    noteDeleted(deletedNote) {
      let noteIndex = this.reservation.Notes.findIndex(
        (n) => n.Id === deletedNote.Id
      )
      if (noteIndex > -1) {
        this.reservation.Notes.splice(noteIndex, 1)
      }
    },

    noteEdited(editedNote) {
      //console.log('edit triggered', editedNote)
      let self = this

      let noteIndex = self.reservation.Notes.findIndex(
        (n) => n.Id === editedNote.Id
      )
      if (noteIndex > -1) {
        self.reservation.Notes.splice(noteIndex, 1, editedNote)
      }
    },
  },
}
</script>
